import React from 'react';
import {
  CalendarOutlined,
  HomeOutlined,
  DisconnectOutlined,
  CrownOutlined,
  ApiOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import './style.css';
import {
  Avatar,
  Menu,
  Tooltip,
  Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function DashBoardBar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  function Divider() {
    return <div className='verticalDivider' />;
  }

  const loginUser = JSON.parse(localStorage.getItem('logInUser'));

  return (
    <div className='DashBoardBar'>
      {/* <SearchSymbolModal /> */}

      {/* Menu Icon */}
      {/* <Tooltip title='Menu'>
        <div className='menu-ctr'>
          <MenuOutlined className='menu-icon' />
        </div>
      </Tooltip>

      <Divider /> */}

      {/* Indicators */}
      <Tooltip title='Home'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/trade/planet_angles')}
        >
          <HomeOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            HOME
          </Typography.Text>
        </div>
      </Tooltip>

      <Divider />

      {/* Indicators */}
      <Tooltip title='Calendar Events'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/trade/events')}
        >
          <CalendarOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            EVENTS
          </Typography.Text>
        </div>
      </Tooltip>

      <Divider />

      <Tooltip title='Calendar Events'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/trade/combinations')}
        >
          <ApiOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            COMBINATIONS
          </Typography.Text>
        </div>
      </Tooltip>

      {/* <Divider /> */}

      {/* Indicators */}
      {/* <Tooltip title='Plant Angles'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/trade/planet_angles')}
        >
          <StockOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            ANGLES
          </Typography.Text>
        </div>
      </Tooltip>

      <Divider /> */}

      {/* <Tooltip title='Subscription Plans'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/pricing/all-plans')}
        >
          <CrownOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            PRICING
          </Typography.Text>
        </div>
      </Tooltip>

      <Divider /> */}

      {/* <Tooltip title='Subscription Details'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/active-plan')}
        >
          <EuroCircleOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            MY SUBSCRIPTION
          </Typography.Text>
        </div>
      </Tooltip>

      <Divider />

      <Tooltip title='Subscription Setting'>
        <div
          className='searchtrade-ctr'
          onClick={() => navigate('/plan-setting')}
        >
          <SettingOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            SETTING
          </Typography.Text>
        </div>
      </Tooltip>

      <Divider /> */}

      {/* Logout */}
      {/* <Tooltip title='LogOut User'>
        <div
          className='searchtrade-ctr logout'
          onClick={() => {
            localStorage.clear();
            dispatch({ type: 'USER_LOGOUT' });
            navigate('/auth/login');
          }}
        >
          <DisconnectOutlined className='searchtrade-icon' />
          <Typography.Text type='secondary' className='searchtrade-name'>
            LogOut
          </Typography.Text>
        </div>
      </Tooltip> */}

        <Menu
          selectedKeys={['mail']}
          onClick={() => {}}
          mode='horizontal'
          className='logout'
          items={[
            {
              label: loginUser.name,
              key: 'SubMenu',
              icon: (
                <Avatar
                  src='https://api.dicebear.com/7.x/miniavs/svg?seed=1'
                  className='avatar_centre'
                />
              ),
              children: [
                {
                  label: 'Pricing',
                  key: 'pricing',
                  icon: <CrownOutlined />,
                  onClick: () => {
                    navigate('/trade/all-plans');
                  },
                },
                {
                  label: 'Reset Password',
                  key: 'reset_password',
                  icon: <RedoOutlined />,
                  onClick: () => {
                    navigate('/auth/reset_password');
                  },
                },
                {
                  label: 'Logout',
                  key: 'setting:2',
                  icon: <DisconnectOutlined />,
                  onClick: () => {
                    localStorage.clear();
                    dispatch({ type: 'USER_LOGOUT' });
                    navigate('/auth/login');
                  },
                },
              ],
            },
          ]}

          // style={{
          //   display: 'flex',
          //   alignItems: 'center',
          //   justifyContent: 'center',
          // }}
        />
    </div>
  );
}
