import { useNavigate, useRouteError } from 'react-router-dom';
import ErrorSVG from './3.svg';
import './error.css';
import { Button } from 'antd';

export default function ErrorPage() {
  const navigate = useNavigate();
  let error = useRouteError();
  return (
    <div id='error-page' className='error_page-ctr'>
      <div className='left-content'>
        <h2 className='error-code'>404</h2>
        <h4 className='error-text'>Page Not Found</h4>
        <Button
          className='error-btn'
          onClick={() => {
            navigate('/');
          }}
        >
          Go Home
        </Button>
      </div>
      <div className='right-content'>
        <img className='error_image' src={ErrorSVG} />
      </div>
    </div>
  );
}
