import {
  Alert,
  Button,
  Card,
  Checkbox,
  Collapse,
  Form,
  Modal,
  Select,
  Spin,
  Tooltip,
  Typography,
  notification,
} from 'antd';
import React, { useEffect, useState } from 'react';
import {
  combinationModeOptions,
  dateFormat,
  getRandomAlphabet,
  getRandomColor,
  goToPositionOnChart,
  neighboursOptions,
  planetAngles,
  planetValuesOptions,
  trueFalse,
} from '../../../Utils/settings';
import {
  CheckOutlined,
  FilterOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { planetEvents } from '../../../Utils/DummyData';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { setScrollPosition } from '../../../redux/dashboardSlice/dashSlice';
import {
  getAngleColumnAction,
  getAngleColumnValuesAction,
  getPlanetAnglesAction,
} from '../../../Screens/Action';
import {
  resetSelectedAngles,
  setIndicatorData,
  setPlanetAngles,
  setPlanetMarker,
  setSelectedAngles,
} from '../../../redux/planetSlice/planetSlice';
import { NoData } from '../../NoData';
import PlanetAnglesFilter from './PlanetAnglesFilter';

const PlanetAnglesSelection = ({ data, loading }) => {
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const [color] = useState(getRandomColor());
  const [alphabet, setAlphabet] = useState();

  useEffect(() => {
    setChecked(false);
  }, [loading]);

  const { planet_angles, selectedChartAngles, indicatorData } = useSelector(
    (state) => state.planet
  );

  const onChecked = (checked) => {
    console.log('color', color, getRandomAlphabet());
    const filteredAngles = planet_angles
      .map((item) => ({
        [data]: item[data],
        date: item.date,
        ...item,
      }))
      .filter((vv) => vv[data]);

      console.log('filteredAngles', filteredAngles)

    if (filteredAngles.length === 0 || !filteredAngles[0].date)
      return notification.error({ message: 'Error In Displaying Data' });

    const isLordShip = trueFalse.test(filteredAngles[0][data]);

    let word = getRandomAlphabet();
    if (isLordShip) {
      setAlphabet(word);
    } else word = null;

    setChecked(checked);

    if (checked) {
      dispatch(
        setSelectedAngles({
          type: 'ADD',
          data: data,
          color: color,
          alphabet: word,
        })
      );
    } else {
      dispatch(setSelectedAngles({ type: 'REMOVE', data: data }));
    }
  };

  const isIndicator = !!indicatorData[data];

  return (
    <div className='checkbox-ctr'>
      <div
        className={`customCheckbox ${checked && 'checked'}`}
        onClick={() => onChecked(!checked)}
      >
        <CheckOutlined className={`customCheck ${!checked && 'uncheck'}`} />
      </div>
      {data}

      {/* <Checkbox checked={checked} onChange={onChecked}>
      </Checkbox> */}
      {checked && (
        <>
          <div
            className='dot'
            style={{
              backgroundColor: isIndicator
                ? indicatorData[data][0].color
                : color,
            }}
          />

          {alphabet && <div>{alphabet}</div>}
        </>
      )}
    </div>
  );
};

export default function PlanetAnglesSection() {
  const { chartData, searchParams } = useSelector((state) => state.dashboard);
  const { planet_angles, selectedChartAngles, indicatorData } = useSelector(
    (state) => state.planet
  );

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // const [planetObj, setPlanetObj] = useState(
  //   Array.isArray(planet_angles) ? Object.keys(planet_angles[0]) : []
  // );
  const [openModal, setopenModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);

  const [angleColumnNames, setAngleColumnNames] = useState([]);
  const [angleColumnValues, setAngleColumnValues] = useState([]);

  const [planetValues, setPlanetValues] = useState([]);
  const [initialVlaues, setInitialValues] = useState({
    planets: [],
    mode: 0,
    // neighbour: 5,
    symbol: searchParams.symbol,
    planet_angles: [],
    planet_angle_values: [],
  });

 

  const getAngleColumnValues = (values) => {
    getAngleColumnValuesAction({ payload: values }).then(
      ({ data, error, message }) => {
        if (!error) {
          setAngleColumnValues(data);
          console.log('data', data);
          // setInitialValues((prev) => ({
          //   ...prev,
          //   planet_values: [data[0]],
          // }));
        }
      }
    );
  };

  const getAngleColumn = () => {
    getAngleColumnAction().then(({ data, error, message }) => {
      if (!error) {
        setAngleColumnNames(data);
        setInitialValues((prev) => ({
          ...prev,
          planets: [data[0]],
        }));

        getAngleColumnValues([data[0]]);
      }
    });
  };

  useEffect(() => {
    getAngleColumn();
  }, []);

  const getData = async () => {
    if (initialVlaues.planets.length === 0) return;

    const payload = [
      {
        ...initialVlaues,
        symbol: searchParams.symbol,
      },
    ];

    setLoading(true);
    await getPlanetAnglesAction({
      payload,
      page_Number: pageNumber,
    })
      .then(({ message, error, data }) => {
        if (!error) {
          data.sort((a, b) => new Date(a.date) - new Date(b.date));

          const keys = Object.keys(data[0]).filter(
            (element) =>
              !['Open', 'High', 'Low', 'Close', 'date'].includes(element)
          );
          console.log('keys', keys);

          // Phase 2: Data Format
          let newObj = {};
          keys.forEach((key) => {
            const color = getRandomColor();
            newObj[key] = data.map((item) => {
              return {
                value: item[key],
                date: item.date,
                color: color,
              };
            });
          });
          console.log('newObj', newObj);

          // Phase 3: Seperating Indicator Data
          let indicatorObj = {};
          keys.forEach((key) => {
            const regex = /^-?[0-9]+(\.[0-9]+)?$/;
            // const value = newObj[key].filter((vv) => vv.value)[0].value;
            const dataIsString = !regex.test(
              newObj[key].filter((vv) => vv.value)[0]?.value
            );
            if (!dataIsString) {
              indicatorObj[key] = newObj[key];
            }
          });
          console.log('indicatorObj', indicatorObj);

          dispatch(setIndicatorData(indicatorObj));

          dispatch(resetSelectedAngles());
          dispatch(setPlanetAngles(data));
        } else {
          dispatch(setPlanetAngles([]));
          notification.error({ message });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  useEffect(() => {
    console.log('searchParams.symbol', searchParams.symbol);
    getData();
  }, [initialVlaues, searchParams]);

  useEffect(() => {
    console.log('refreshedddddddddddddddd')
  }, [searchParams])

  function modalClosed() {
    setopenModal(false);
  }

  function onFinish(values) {
    setInitialValues((prev) => ({ ...prev, ...values }));
    setopenModal(false);
  }

  return (
    <>
      <PlanetAnglesFilter
        initialVlaues={initialVlaues}
        modalClosed={modalClosed}
        onFinish={onFinish}
        openModal={openModal}
      />
      
      {/* <FilterModal /> */}
      <div className='filter_ctr'>
        <Tooltip placement='left' title='Filter Angles'>
          <Button
            className='filter_icon'
            onClick={() => setopenModal(true)}
            icon={<FilterOutlined />}
          />
        </Tooltip>
      </div>
      <Spin tip='Loading...' spinning={loading}>
        {planet_angles?.length > 0 ? (
          Object.keys(planet_angles[0])
            .filter(
              (element) =>
                !['Open', 'High', 'Low', 'Close', 'date'].includes(element)
            )
            .map((item) => {
              return <PlanetAnglesSelection data={item} loading={loading} />;
            })
        ) : loading ? null : (
          <NoData />
        )}
      </Spin>
    </>
  );
}
