import React, { useEffect } from 'react';
import DashBoardBar from '../Components/dashBoardBar';
import TurningPointSection from '../Components/dashTurningPoint';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

export default function Layout() {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('token'));

    if (!token) {
      localStorage.clear();
      dispatch({ type: 'USER_LOGOUT' });
    }
  }, []);

  const noTurningPointSectionRoutes = ['/events', '/trade/all-plans'];

  return (
    <div className='dashboard_container'>
      <DashBoardBar />

      <div className='section_container'>
        <Outlet />
        {!noTurningPointSectionRoutes.includes(pathname) && (
          <TurningPointSection />
        )}
      </div>
    </div>
  );
}
