import { CrosshairMode } from 'lightweight-charts';
import { setScrollPosition } from '../redux/dashboardSlice/dashSlice';
import Logo from './../Assets/logo.png';

export const API_URL = process.env.REACT_APP_API_URL;

// Static Links
export const tradingViewLink = 'https://in.tradingview.com';

// Images
export const tradingViewLogo = Logo;

export function isLoggedIn() {
  const token = localStorage?.getItem('token');
  return !!token;
}


export const trueFalse = /^(true|false)$/i;

export function getRandomAlphabet() {
  // Generate a random number between 0 and 25 (representing the 26 letters of the alphabet)
  const randomIndex = Math.floor(Math.random() * 26);

  // Convert the random index to a character code and then to the corresponding alphabet letter
  const randomAlphabet = String.fromCharCode('A'.charCodeAt(0) + randomIndex);

  return randomAlphabet;
}

// CandleSeries
const greenStickColor = 'rgba(0, 150, 136, 0.8)';
const redStickColor = 'rgba(255,82,82, 0.8)';

export const candleStickSeries = {
  upColor: greenStickColor,
  downColor: redStickColor,

  borderDownColor: redStickColor,
  borderUpColor: greenStickColor,
  
  wickDownColor: redStickColor,
  wickUpColor: greenStickColor,
};

export const areaSeriesConfig = {
  topColor: 'rgba(38,198,218, 0.56)',
  bottomColor: 'rgba(38,198,218, 0.04)',
  lineColor: 'rgba(38,198,218, 1)',
  lineWidth: 2,
};

export const currencyEnums = {
  INR: 1,
  USD: 2,
};

export const billingCycleEnums = {
  FREE: 'FREE',
  MONTHLY: 1,
  YEARLY: 2,
  LIFETIME: 3,
};

export const plansBenefits = {
  FREE: [
    'Supports two symbols.',
    'Includes one astrological indicator for a planet.',
    'Supports all technical indicators.',
  ],
  [billingCycleEnums['MONTHLY']]: [
    'Unlimited symbol support.',
    'Includes all planet astrological indicators.',
    'Supports all technical indicators.',
  ],
  [billingCycleEnums['YEARLY']]: [
    'Unlimited symbol support.',
    'Includes all planet astrological indicators.',
    'Supports all technical indicators.',
    'Requests for adding new symbols are included.',
  ],
};

export function getKeyByValue(object, targetValue) {
  for (const key in object) {
    if (object.hasOwnProperty(key) && object[key] === targetValue) {
      return key;
    }
  }
  return null; // Return null if the value is not found
}

export const lineSeriesConfig = {
  color: getRandomColor(),
  lineWidth: 2,
};

export const chartConfig = {
  layout: {
    background: {
      type: 'solid',
    },
    textColor: 'rgba(0, 0, 0, 0.9)',
  },
  grid: {
    vertLines: {
      color: 'rgba(186, 191, 194, 0.5)',
    },
    horzLines: {
      color: 'rgba(186, 191, 194, 0.5)',
    },
  },
  crosshair: {
    mode: CrosshairMode.Normal,
  },
  rightPriceScale: {
    borderColor: 'rgba(197, 203, 206, 0.8)',
  },
};

export const getToken = () => {
  const token = JSON.parse(localStorage.getItem('token'));
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const goToPositionOnChart = ({ date, data }) => {
  // date format 2023-01-03
  const candleData = data;

  const lastIndex = candleData.findIndex((item) => item.time === date);

  if (lastIndex !== -1) {
    const positionFromBack = candleData.length - 1 - lastIndex - 50;
    return positionFromBack;
    // dispatch(setScrollPosition(positionFromBack));
  } else return null;
};

export const resetChartPosition = ({ dispatch }) => {
  dispatch(setScrollPosition(0));
};

export const validateNumber = (_, value) => {
  const regex = /^[0-9]+(\.[0-9]+)?$/;
  if (value && !regex.test(value)) {
    return Promise.reject('Please enter a valid number.');
  }
  return Promise.resolve();
};

export function getRandomColor() {
  // Generate a random hexadecimal color code
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const colIncluded = [
  'First',
  'Second',
  'Third',
  'Fourth',
  'Fifth',
  'Sixth',
  'Seventh',
  'Eighth',
  'Ninth',
  'Tenth',
  'Eleventh',
  'Twelfth',
  'Mercury',
  'Venus',
  'Mars',
  'Jupiter',
  'Saturn',
  'True_Node',
  'dates_sum',
  'Sun',
];

export const dateFormat = 'YYYY-MM-DD'

export const planetAngles = [
  { label: 'Mercury', value: 'mercury' },
  { label: 'Venus', value: 'venus' },
  { label: 'Mars', value: 'mars' },
  { label: 'Jupiter', value: 'jupiter' },
  { label: 'Saturn', value: 'saturn' },
  { label: 'True Node', value: 'true_node' },
  { label: 'Sun', value: 'sun' },
];

const combinationMode = {
  geocentric: 0,
  heliocentric: 1
}

export const combinationModeOptions = [
  { label: 'Geocentric', value: combinationMode['geocentric'] },
  { label: 'Heliocentric', value: combinationMode['heliocentric'] },
]

const neighbours = {
  5: 5,
  10: 10,
  15: 15,
  20: 20,
};

export const neighboursOptions = [
  { label: '5', value: neighbours['5'] },
  { label: '10', value: neighbours['10'] },
  { label: '15', value: neighbours['15'] },
  { label: '20', value: neighbours['20'] },
];

export const planetValuesOptions = [
  { label: 'Sign', value: 'sign' },
  { label: 'Element', value: 'element' },
  { label: 'Retrograde', value: 'retrograde' },
  { label: 'Abs Pos', value: 'abs_pos' },
];

export const testData = [
  {
    combinations: 'VenusTrue_Nodeangle_sum, Saturn_abs_pos_sum',
    values: '1.0, 1.0',
    dates: [
      {
        date: '2021-02-16',
        swing_count: true,
      },
      {
        date: '2011-12-28',
        swing_count: true, // Show Green
      },
      {
        date: '2017-07-07',
        swing_count: false, // Show Red
      },
      {
        date: '2009-11-09',
        swing_count: true,
      },
      {
        date: '2021-10-25',
        swing_count: true,
      },
    ],
    'count_day_-5': 1,
    'count_day_-4': 0,
    'count_day_-3': 0,
    'count_day_-2': 0,
    'count_day_-1': 0,
    count_day_0: 1,
    count_day_1: 0,
    count_day_2: 0,
    count_day_3: 0,
    count_day_4: 2,
    count_day_5: 2,
    sum_of_all_count: 6,
    total_count: 6,
    success_rate: 100,
    total_dates: [
      '2009-11-09',
      '2011-12-28',
      '2017-07-07',
      '2019-05-27',
      '2021-02-16',
      '2021-10-25',
    ],
  },
];

const res = {
  combinations: 'VenusTrue_Nodeangle_sum, Saturn_abs_pos_sum',
  values: '1.0, 1.0',
  dates: [
    '2021-02-16',
    '2011-12-28',
    '2017-07-07',
    '2009-11-09',
    '2021-10-25',
    '2019-05-27',
  ],
  'count_day_-5': 1,
  'count_day_-4': 0,
  'count_day_-3': 0,
  'count_day_-2': 0,
  'count_day_-1': 0,
  count_day_0: 1,
  count_day_1: 0,
  count_day_2: 0,
  count_day_3: 0,
  count_day_4: 2,
  count_day_5: 2,
  sum_of_all_count: 6,
  total_count: 3,
  success_rate: 100,
  total_dates: [
    '2009-11-09',
    '2011-12-28',
    '2017-07-07',
    '2019-05-27',
    '2021-02-16',
    '2021-10-25',
  ],
};
