import React, { useEffect, useRef } from 'react';
import { widget } from '../../charting_library';
import DATAFEED from './datafeed';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { angleData } from '../../Utils/DummyData';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

export const ChartContainer = () => {
  const chartContainerRef = useRef();
  const markChartRef = useRef();
  let widgetRef = useRef();
  // const { turningPoints } = useSelector((state) => state.dashboard);
  const {
    turningPoints,
    chartData,
    scrollPosition,
    angleCombinations,
    collapseOppened,
    uniqueDates,
    searchParams,
    // candleMarkers
  } = useSelector((state) => state.dashboard);

  const { candleMarkers, indicatorData, selectedChartAngles } = useSelector(
    (state) => state.planet
  );

  const defaultProps = {
    symbol: searchParams.symbol ? searchParams.symbol : '^NSEI',
    interval: 'D',
    datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: '/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
  };

  useEffect(() => {
    // if (chartData.length === 0) return;
    const widgetOptions = {
      symbol: defaultProps.symbol,
      // BEWARE: no trailing slash is expected in feed URL
      datafeed: DATAFEED,
      interval: defaultProps.interval,
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath,
      timezone: 'Asia/Kolkata',

      locale: getLanguageFromURL() || 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      // toolbar_bg: '#ebf5fb',
      // drawings_access: {
      //   type: 'white',
      //   tools: [
      //     {
      //       name: 'Trend Line',
      //       // grayed: true
      //     },
      //     {
      //       name: 'Vertical Line',
      //       // grayed: true
      //     },
      //     {
      //       name: 'Circle',
      //       // grayed: true
      //     },
      //   ],
      // },
      numeric_formatting: { decimal_sign: '.' },
      // theme: "dark",
      // disabled_features: ["header_widget", "legend_widget", "timeframes_toolbar", "two_character_bar_marks_labels"],
      disabled_features: [
        'edit_buttons_in_legend',
        'format_button_in_legend',
        'show_hide_button_in_legend',
        'context_menus',

        // header Settings
        'header_chart_type',
        'header_compare',
        'header_fullscreen_button',
        // 'header_indicators',
        'header_resolutions',
        'header_screenshot',
        'header_settings',
        // 'header_symbol_search',
        'header_undo_redo',

        // 'legend_widget',
        'timeframes_toolbar',
        'two_character_bar_marks_labels',
      ],
      enabled_features: ['two_character_bar_marks_labels'],
      overrides: {
        'paneProperties.background': '#ebf5fb',
        // 'paneProperties.backgroundType': 'gradient',
        // 'paneProperties.backgroundGradientStartColor': '#ebf5fb',
        // 'paneProperties.backgroundGradientEndColor': '#7e98b9',
        'paneProperties.vertGridProperties.color': '#3f5885',
        'paneProperties.vertGridProperties.style': 1,
        'paneProperties.horzGridProperties.color': '#3f5885',
        'paneProperties.horzGridProperties.style': 1,

        'paneProperties.separatorColor': '#3f5885',

        'crossHairProperties.color': '#FF6026',
      },

      // numeric_formatting: {
      //   decimal_sign: '.',
      // },
      // priceFormat: {
      //   type: "price",
      //   precision: 6,
      //   minMove: 0.000001,
      // },
      //
      custom_indicators_getter: function (PineJS) {
        const indicators = Object.keys(indicatorData).map((item, index) => {
          const vv = indicatorData[item];
          return {
            name: `${item}`,
            metainfo: {
              _metainfoVersion: 51,
              id: `${index}_${item}@tv-basicstudies-${index}`,
              description: `${item}`,
              shortDescription: `${index + 1} ${item}`,
              is_price_study: false,
              isCustomIndicator: true,
              plots: [
                {
                  id: 'plot_0',
                  type: 'line',
                },
              ],
              defaults: {
                // ...
                styles: {
                  plot_0: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 0,

                    // PLOTTYPE BELOW
                    // 0- line
                    // 1- histogram
                    // 3- cross
                    // 4- area
                    // 5- columns
                    // 6- circles
                    // 7- line with breaks
                    // 8- area with breaks
                    // 9- step line
                    trackPrice: false,
                    transparency: 0,
                    visible: true,
                    color: vv[0].color,
                  },
                },
              },
              styles: {
                plot_0: {
                  title: 'Plot',
                  histogramBase: 0,
                  joinPoints: false,
                },
              },
              // styles: {
              //   plot_0: {
              //     title: 'Equity value',
              //     histogramBase: 0,
              //   },
              // },
              inputs: [],
              format: {
                type: 'price',
                precision: 4,
              },
            },
            constructor: function () {
              // this.init = function (context, inputCallback) {
              //   var symbol = '#EQUITY';
              //   var period = PineJS.Std.period(this._context);
              //   context.new_sym(symbol, period);
              // };

              function binarySearch(data, targetDate) {
                let left = 0;
                let right = data.length - 1;

                while (left <= right) {
                  const mid = Math.floor((left + right) / 2);
                  const midDate = data[mid].date;

                  if (midDate === targetDate) {
                    return data[mid];
                  } else if (midDate < targetDate) {
                    left = mid + 1;
                  } else {
                    right = mid - 1;
                  }
                }

                // If the target date is not found, you can handle it accordingly (return null, throw an error, etc.)
                return null;
              }

              this.main = function (context, inputCallback) {
                const targetDate = dayjs(context.symbol.time).format(
                  'YYYY-MM-DD'
                );
                const result = binarySearch(vv, targetDate);
                if (!result) return 0;
                else return result.value;

                // return result.value;
              };
            },
          };
        });

        return Promise.resolve(indicators);
      },

      // custom_indicators_getter: function (PineJS) {
      //   // return Promise.resolve([
      //   //   {
      //   //     name: '1 Indicator',
      //   //     metainfo: {
      //   //       _metainfoVersion: 51,
      //   //       id: '1indicator@tv-basicstudies-1',
      //   //       description: '1 Indicator',
      //   //       shortDescription: '1 Indicator',
      //   //       is_price_study: false,
      //   //       isCustomIndicator: true,
      //   //       plots: [
      //   //         {
      //   //           id: 'plot_0',
      //   //           type: 'line',
      //   //         },
      //   //       ],
      //   //       styles: {
      //   //         plot_0: {
      //   //           title: 'Equity value',
      //   //           histogramBase: 0,
      //   //         },
      //   //       },
      //   //       inputs: [],
      //   //       format: {
      //   //         type: 'price',
      //   //         precision: 4,
      //   //       },
      //   //     },
      //   //     constructor: function () {
      //   //       this.main = function (context, inputCallback) {
      //   //         this._context = context;
      //   //         this._input = inputCallback;

      //   //         const value = Number(context.symbol.close);

      //   //         return [value];
      //   //       };
      //   //     },
      //   //   },
      //   // ]);
      // },
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      widgetRef.current = tvWidget.activeChart();
      tvWidget.addCustomCSSFile('./chartHeader.css');

      // tvWidget.activeChart().removeAllShapes()

      // tvWidget
      //   .headerReady()
      //   .then(() => {
      //     const button = tvWidget.createButton();
      //     button.setAttribute('title', 'Click to show a notification popup');
      //     button.classList.add('apply-common-tooltip');
      //     button.addEventListener('click', () =>
      //       tvWidget.showNoticeDialog({
      //         title: 'Notification',
      //         body: 'TradingView Charting Library API works correctly',
      //         callback: () => {},
      //       })
      //     );

      //     button.innerHTML = 'Check API';
      //   })
      //   .catch((err) => console.log(err));

      // const arr = [
      //   turningPoints[turningPoints.length - 3],
      //   turningPoints[turningPoints.length - 2],
      //   turningPoints[turningPoints.length - 1],
      // ];

      // let currentIndex = 0;
      // let reverse = false;
      // arr.forEach((item, index) => {
      //   if (index - 1 === currentIndex) return;
      //   if (index - 2 === currentIndex) reverse = true;
      //   currentIndex = index;
      //   tvWidget.activeChart().createMultipointShape(
      //     [
      //       {
      //         price: item.open,
      //         time: dayjs(item.time).add(1, 'day').unix(),
      //       },
      //       {
      //         price: reverse ? arr[index - 1].open : arr[index + 1].open,
      //         time: reverse
      //           ? dayjs(arr[index - 1].time)
      //               .add(1, 'day')
      //               .unix()
      //           : dayjs(arr[index + 1].time)
      //               .add(1, 'day')
      //               .unix(),
      //       },
      //       // { price: 175.534533407091, time: 1509408000 },
      //     ],
      //     { shape: 'circle', lock: true }
      //   );
      //   reverse = false;
      // });

      // tvWidget
      //   .activeChart()
      //   .getAllShapes()
      //   .map((item) => {
      //     console.log('item', tvWidget.activeChart().getShapeById(item.id));
      //   });

      // [
      //   {
      //     time: '2023-06-27',
      //     position: 'aboveBar',
      //     color: '#131DFA',
      //     shape: 'arrowDown',
      //     text: 'ML_false',
      //     lordShip: 'false',
      //   },
      //   {
      //     time: '2023-07-31',
      //     position: 'aboveBar',
      //     color: '#131DFA',
      //     shape: 'arrowDown',
      //     text: 'ML_true',
      //     lordShip: 'true',
      //   },
      //   {
      //     time: '2023-10-05',
      //     position: 'aboveBar',
      //     color: '#131DFA',
      //     shape: 'arrowDown',
      //     text: 'ML_false',
      //     lordShip: 'false',
      //   },
      // ].forEach((item) => {
      //   tvWidget
      //     .activeChart()
      //     .createShape(
      //       { time: dayjs(item.time).unix() },
      //       { shape: 'arrow_up', text: 'hello' }
      //     );
      // });

      // tvWidget.activeChart().createMultipointShape(
      //   [
      //     { price: 21779, time: 1703788200 },
      //     { price: 22032, time: 1705429800 },
      //     // { price: 175.534533407091, time: 1509408000 },
      //   ],
      //   { shape: 'circle', lock: true }
      // );

      // tvWidget.activeChart().createMultipointShape(
      //   [
      //     { price: 21352.599609375, time: 1706207400 },
      //     { price: 22032, time: 1705429800 },
      //     // { price: 175.534533407091, time: 1509408000 },
      //   ],
      //   { shape: 'circle', lock: true }
      // );

      // tvWidget
      //   .activeChart()
      //   .getAllShapes()
      //   .map((shape) => {
      //     console.log(
      //       'prop',
      //       tvWidget.activeChart().getShapeById(shape.id).getProperties()
      //     );
      //   });

      // tvWidget.activeChart()

      // tvWidget.activeChart()
      //   .setVisibleRange(
      //     { from: dayjs('2014-09-11').unix(), to: dayjs('2015-02-11') },
      //     { percentRightMargin: 20 }
      //   )
      //   .then(() => console.log('New visible range is applied'));

      // tvWidget.activeChart().createStudy('MACD', false, false, {
      //   in_0: 14,
      //   in_1: 30,
      //   in_3: 'close',
      //   in_2: 9,
      // });
      // tvWidget
      //   .activeChart()
      //   .createStudy('Moving Average Exponential', false, false, {
      //     length: 26,
      //   });
      // tvWidget.activeChart().createStudy('1 Sun_sign_num_sum');
      // tvWidget
      //   .activeChart()
      //   .createStudy('Price Channel', true, false, { in_0: 26 }, null, {
      //     checkLimit: false,
      //     priceScale: 'new-left',
      //   });

      // console.log(turningPoints[0]);
      // turningPoints.forEach((item) => {
      //   // console.log('jhgbjhdgf', item);
      //   tvWidget
      //     .activeChart()
      //     .createShape(
      //       { time: dayjs(item.time).add(1, 'day').unix() },
      //       { shape: item.greenStick ? 'arrow_up' : 'arrow_down', lock: true }
      //     );
      // });

      // turningPoints.map((item) =>
      //   tvWidget
      //     .activeChart()
      //     .createShape(
      //       { time: dayjs(item.time).add(1, 'day').unix() },
      //       { shape: 'flag', lock: true }
      //     )
      // );

      // tvWidget.activeChart().

      // const value = collapseOppened[0];

      // if (value === '2') {
      //   angleCombinations.length > 0 &&
      //     angleCombinations.forEach((item) => {
      //       tvWidget
      //         .activeChart()
      //         .createShape(
      //           { time: dayjs(item.time).add(1, 'day').unix() },
      //           { shape: 'vertical_line', lock: true }
      //         );
      //     });
      // } else {
      //   if (turningPoints.length > 0) {
      //     turningPoints.map((item) =>
      //       tvWidget
      //         .activeChart()
      //         .createShape(
      //           { time: dayjs(item.time).add(1, 'day').unix() },
      //           { shape: 'flag', lock: true }
      //         )
      //     );
      //   }
      // }

      // Drawing TrendLine on Chart
      // tvWidget.activeChart().createMultipointShape(
      //   [
      //     { time: dayjs('2023-10-27').add(1, 'day').unix(), price: 19047.25 },
      //     { time: dayjs('2024-01-11').add(1, 'day').unix(), price: 21647.2 },
      //   ],
      //   {
      //     shape: 'arrow',
      //     lock: true,
      //     disableSelection: true,
      //     disableSave: true,
      //     disableUndo: true,
      //     text: 'text',
      //   }
      // );

      // marking on Chart
      // if (turningPoints.length > 0) {
      //   // turningPoints.map((item) =>
      //   //   tvWidget
      //   //     .activeChart()
      //   //     .createShape(
      //   //       { time: dayjs(item.time).add(1, 'day').unix() },
      //   //       { shape: 'flag', lock: true }
      //   //     )
      //   // );
      // }

      // The subscribe method allows you to subscribe to the widget's events and handle them. For example, the code sample below handles an event when an indicator is added to the chart and prints the indicator's name to the console.
      // tvWidget.subscribe('study', (event) => {
      //   console.log(`A ${event.value} indicator was added`);
      // });
      // turningPoints.map((item) =>
      //   tvWidget
      //     .activeChart()
      //     .createShape(
      //       { time: dayjs(item.time).add(1, 'day').unix() },
      //       { shape: 'flag', lock: true }
      //     )
      // );

      // markChartRef.current = markCharts;
      // console.log(
      //   'vvvv',
      //   dayjs(tvWidget.activeChart().getVisibleRange().from * 1000).format(
      //     'DD MMM YYYY'
      //   ), dayjs(tvWidget.activeChart().getVisibleRange().to * 1000).format(
      //     'DD MMM YYYY'
      //   )
      // );

      // const keys = Object.keys(candleMarkers);
      // if (keys.length === 0) return;

      // keys.forEach((key) => {
      //   console.log('candle', candleMarkers[key])
      //   candleMarkers[key].forEach((vv) => {
      //     tvWidget
      //       .activeChart()
      //       .createShape(
      //         { time: dayjs(vv.time).add(1, 'day').unix() },
      //         { shape: 'arrow_up', lock: true, text: vv.text }
      //       );
      //   });
      // });
    });

    return () => {
      tvWidget.remove();
    };
  }, [indicatorData]);

  useEffect(() => {
    if (collapseOppened.length === 0 || collapseOppened[0] === '1') {
      return widgetRef?.current?.refreshMarks();
    } else {
      widgetRef?.current?.clearMarks();
      widgetRef?.current?.refreshMarks();
    }
  }, [collapseOppened]);

  useEffect(() => {
    widgetRef?.current?.clearMarks();
    widgetRef?.current?.refreshMarks();
  }, [candleMarkers, uniqueDates]);

  useEffect(() => {
    widgetRef?.current?.removeAllStudies();
    Object.keys(selectedChartAngles).forEach((key) => {
      widgetRef?.current?.createStudy(key);
    });
  }, [selectedChartAngles]);

  // useEffect(() => {
  //   console.log('turningPoints', turningPoints);
  //   if (widgetRef?.current) {

  //   }
  // }, [turningPoints]);

  return <div ref={chartContainerRef} className={'dashboard_chart'} />;
};
