import { Button, Form, Input, Layout, notification } from 'antd';
import React, { useState } from 'react';
import './auth.css';
import { requestPasswordResetAction } from './Action';

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async (values) => {
    setLoading(true);

    await requestPasswordResetAction({ email: values.email })
      .then(({ message, error }) => {
        if (!error) {
          notification.success({ message });
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log(err));
      
    setLoading(false);
  };

  return (
    <Layout className='login-ctr'>
      <div className='login-box'>
        <h2 className='login_title'>Reset Password</h2>
        <p className='login_subtitle'>
          Please enter the email address associated with your account, and we
          will promptly send you an email containing a confirmation link to
          facilitate the password reset process.
        </p>

        <Form
          form={form}
          className='login-form_ctr'
          onFinish={onFinish}
          autoComplete={false}
        >
          <p className='item_title'>Registered Email</p>
          <Form.Item
            name={'email'}
            rules={[
              {
                type: 'email',
                message: 'Please enter a valid email address!',
              },
              {
                required: true,
                message: 'Email is required!',
              },
            ]}
          >
            <Input placeholder='example@gmail.com' className='form-item' />
          </Form.Item>

          <Form.Item>
            <Button
              loading={loading}
              //   icon={<LoginOutlined />}
              htmlType='submit'
              className='form-item form-item-btn '
            >
              Continue
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Layout>
  );
}
