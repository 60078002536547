import React, { useState } from 'react';
import './index.css';
import {
  ArrowRightOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  DownloadOutlined,
  FileAddOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Collapse,
  Modal,
  Tooltip,
  Typography,
  Upload,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import {
  removeTurningPoint,
  resetTurningPoint,
  setCollapse,
  setScrollPosition,
} from '../../redux/dashboardSlice/dashSlice';
import { useLocation } from 'react-router-dom';
import { goToPositionOnChart } from '../../Utils/settings';
import dayjs from 'dayjs';
import AngleCombinationPanel from './SubComponents/AngleCombinationPanel';
import PlanetAnglesSection from './SubComponents/PlanetAnglesSection';

export default function TurningPointSection() {
  const { pathname } = useLocation();
  const { turningPoints, chartData } = useSelector((state) => state.dashboard);

  const dispatch = useDispatch();

  const [CollapseIndex, setCollapseIndex] = useState(['1']);
  const [isExpanded, setIsExpanded] = useState(true);
  const [open, setOpen] = useState(false);

  const isEventPage = pathname === '/event_details';
  const planetAnglePage = pathname === '/trade/planet_angles';

  // Event States
  const { eventData, selectedEventList } = useSelector((state) => state.event);

  const TurningPointModal = () => {
    return (
      <Modal centered open={open} onCancel={closeModal} closable={false}>
        <div className='turningpoint_modal-ctr'>
          <div className='turningpoint_btn-ctr'>
            <Upload
              name='file'
              accept='.xls, .xlsx, .ods, .csv, .xlsm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv'
              maxCount={1}
            >
              <Button
                type='primary'
                className='csv-btn'
                icon={<FileAddOutlined />}
              >
                Upload CSV
              </Button>
            </Upload>
            {/* <Button type='primary' className='csv-btn'>
              Select On Chart
            </Button> */}
          </div>
        </div>
      </Modal>
    );
  };

  function closeModal() {
    setOpen(false);
  }

  function openModal() {
    setOpen(true);
  }

  function removeMarkerFRomChart(data) {
    dispatch(removeTurningPoint(data));
  }

  function clearAllPoints() {
    dispatch(resetTurningPoint());
  }

  function mainCollapseChange(value) {
    setCollapseIndex(value);
    dispatch(setCollapse(value));
  }

  return (
    <div
      className={`dashboard_right-section`}
      style={{
        width: isExpanded ? '40%' : '2.5%',
      }}
    >
      <TurningPointModal />

      <div
        className='expand_section'
        onClick={() => {
          setIsExpanded((prev) => !prev);
        }}
      >
        {isExpanded ? (
          <RightOutlined className='expand_icon' />
        ) : (
          <LeftOutlined className='expand_icon' />
        )}
      </div>

      {isExpanded && (
        <>
          {!planetAnglePage && (
            <Collapse
              defaultActiveKey={isEventPage ? ['3'] : CollapseIndex}
              accordion
              onChange={mainCollapseChange}
            >
              {/*  */}
              {!isEventPage && (
                <>
                  <Collapse.Panel
                    header={`Turning Points (${turningPoints.length} Points)`}
                    key='1'
                  >
                    <div className='turning_point-ctr'>
                      <p className='turning_point-text'>
                        Selected Turning Points
                      </p>
                      <Tooltip title='Add Turning Points' placement='left'>
                        <PlusOutlined
                          onClick={openModal}
                          className='turning_point-icon'
                        />
                      </Tooltip>
                      {turningPoints.length > 0 && (
                        <>
                          <Tooltip
                            title='Upload Selected Turning Points'
                            placement='left'
                          >
                            <CloudUploadOutlined
                              onClick={clearAllPoints}
                              className='turning_point-icon upload-icon'
                            />
                          </Tooltip>
                          <Tooltip title='Clear All Points' placement='left'>
                            <DeleteOutlined
                              onClick={clearAllPoints}
                              className='turning_point-icon delete-icon'
                            />
                          </Tooltip>
                        </>
                      )}
                    </div>

                    <div
                      style={{
                        marginTop: 10,
                      }}
                    >
                      {turningPoints.map((vv, ii) => (
                        <div
                          className='marked_point-ctr'
                          onClick={() => {
                            const position = goToPositionOnChart({
                              date: moment(vv.time).format('YYYY-MM-DD'),
                              data: chartData,
                            });
                            if (position) dispatch(setScrollPosition(position));
                          }}
                        >
                          <p className='marker_text'>
                            {/* {ii + 1}: */}
                            <ArrowRightOutlined />
                            <span className='marker_date'>
                              {moment(vv.time).format('DD MMM YYYY')}
                            </span>{' '}
                          </p>
                          <Tooltip title='Remove Marker' placement='left'>
                            <DeleteOutlined
                              onClick={() => removeMarkerFRomChart(vv)}
                              className='delete_icon'
                            />
                          </Tooltip>
                        </div>
                      ))}
                    </div>
                  </Collapse.Panel>

                  <Collapse.Panel header={'Angle Combination'} key='2'>
                    <AngleCombinationPanel />
                  </Collapse.Panel>
                </>
              )}

              {isEventPage && (
                <Collapse.Panel header='Event Details' key='3'>
                  <Card className='moon_phase-card'>
                    {/* <h3 className='moon_phase-dates'>Stats</h3> */}
                    <div className='moon_phase_ctr'>
                      <p className='text'>
                        Event Date:{' '}
                        <span>
                          {dayjs(eventData.date).format('DD MMM YYYY')}
                        </span>
                      </p>
                      <p className='text'>
                        Pattern Name: <span>{eventData.pattern_name}</span>
                      </p>
                      <p className='text'>
                        Succcess Rate: <span>{eventData.success_rate} %</span>
                      </p>
                      <p className='text'>
                        Day 0 Success Rate:{' '}
                        <span>{eventData.day_zero_success_rate || '-'}</span>
                      </p>
                      <p className='text'>
                        Total Event: <span>{eventData.total_even}</span>
                      </p>
                      <p className='text'>
                        Positive Events: <span>{eventData.positive_event}</span>
                      </p>
                      <p className='text'>
                        Negative Events: <span>{eventData.negative_event}</span>
                      </p>
                    </div>

                    <AngleCombinationPanel />
                  </Card>
                </Collapse.Panel>
              )}
            </Collapse>
          )}

          {planetAnglePage && (
            <>
              {/* Modal */}
              <PlanetAnglesSection />
            </>
          )}
        </>
      )}
      {/*    */}
    </div>
  );
}
