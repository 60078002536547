import React from 'react';

import './videos.css';

const Videos = () => {
  return (
    <div className='videos-ctr'>
      <h2 className='ctr-header video-header'>Videos</h2>

      <div class='video-grid'>
        {/* <!-- Rows and Columns will be dynamically generated using CSS --> */}
        <div className='grid-item'>
          <iframe
            src='https://www.youtube-nocookie.com/embed/5v-EvbNiPxQ?si=OM-e7PMSWNA6qUNd'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
          />

          <h3>
            Unlock the Power of Automated Trading: From TradingView Signals to
            Interactive Brokers Execution
          </h3>

          <p>
            Dive into the future of trading with our cutting-edge guide on
            automating your TradingView strategies directly into Interactive
            Brokers for lightning-fast execution. Discover how to seamlessly
            bridge the gap between analysis and action, ensuring you never miss
            a beat in the fast-paced world of trading. Join us to revolutionize
            your trading experience and maximize your potential with every
            trade!
          </p>
        </div>

        <div className='grid-item'>
          <iframe
            src='https://www.youtube-nocookie.com/embed/SgiLb37f_XE?si=PDh6uhZmAJs9WUmn'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
          />

          <h3>Dash Trader Integration with Trading View</h3>

          <p>
            We now have integration of Dash Trader with TradingView so you can
            automate your trading view strategy into das trader .
          </p>
        </div>

        <div className='grid-item'>
          <iframe
            src='https://www.youtube-nocookie.com/embed/W4rKcLEbEgA?si=mAdbWky6xk7WVagT'
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
          />

          <h3>TradingView with Tradovate</h3>

          <p>
            Using this product you connect your tradovate account with
            TradingView so trading View stratgey /Indicator can be automated to
            place buy and sell trade to Tradovate account.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Videos;
