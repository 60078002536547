import { createSlice, current } from '@reduxjs/toolkit';
import moment from 'moment';
import { moonPhase } from '../../Utils/DummyData';
import dayjs from 'dayjs';

const initialState = {
  // value: 0,
  turningPoints: [],
  angleCombinations: [],
  chartData: [],
  moonPhaseCombinations: [],
  selectedMoonPhaseCombinations: [],
  scrollPosition: 0,
  searchParams: {
    symbol: '^NSEI',
  },

  uniqueDates: [],
  uniqueDatesCombination: [],

  collapseOppened: ['1'],

  // Loaders
  combinationLoading: false,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setCombinationLoader: (state, { payload }) => {
      state.combinationLoading = payload;
    },
    setUniqueDates: (state, { payload: { type, newData } }) => {
      function removeDuplicates(arr) {
        const uniqueArray = arr.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.date === obj.date)
        );
        return uniqueArray;
      }

      function removeMatchingObjects(arr, newData) {
        try {
          const uniqueArray = arr.filter(
            (obj) => !newData.some((newObj) => obj.date === newObj.date)
          );
          return uniqueArray;
        } catch (error) {
          console.log('err', error);
        }
      }

      if (type === 'ADD') {
        state.uniqueDates = removeDuplicates(
          [...state.uniqueDates, ...newData].sort(
            (a, b) => dayjs(a.date) - dayjs(b.date)
          )
        );
      } else if (type === 'REMOVE') {
        state.uniqueDates = removeMatchingObjects(
          current(state.uniqueDates),
          newData
        );
      } else if (type === 'RESET') {
        state.uniqueDates = [];
      }
    },
    setSearchParams: (state, action) => {
      console.log('action', action.payload);
      state.searchParams = action.payload;
    },
    setCollapse: (state, action) => {
      state.collapseOppened = action.payload;
    },
    resetAngleCombination: (state) => {
      state.angleCombinations = [];
    },
    setAngleCombination: (state, action) => {
      let prevData = current(state.angleCombinations);
      const newDataArr = action.payload.data;
      const checked = action.payload.checked;

      const sorter = (arr) => {
        return arr.sort((a, b) => new Date(a.time) - new Date(b.time));
      };

      if (checked) {
        prevData = sorter([...prevData, ...newDataArr]);
        state.angleCombinations = prevData;
      } else {
        state.angleCombinations = prevData.filter(
          (item) =>
            !newDataArr.some((removeItem) => item.time === removeItem.time)
        );
      }
    },
    // Handle Indidual data
    addTurningPoint: (state, action) => {
      const newData = action.payload;

      const closedHigh = newData.close > newData.open;

      const tempArray = state.turningPoints;

      const index = tempArray.findIndex((item) => item.time === newData.time);

      if (index !== -1) {
        // If the time already exists in arr, remove it
        tempArray.splice(index, 1);
      } else {
        // If the time doesn't exist, add it in ascending order
        tempArray.push({
          time: newData.time,
          position: closedHigh ? 'aboveBar' : 'belowBar',
          color: '#0F6CBD',
          shape: closedHigh ? 'arrowDown' : 'arrowUp',
          text: moment(newData.time).format('DD MMM'),
        });
        tempArray.sort((a, b) => new Date(a.time) - new Date(b.time));
      }

      state.turningPoints = tempArray;
    },

    // Handle an array
    addDefaultTurningPoint: (state, action) => {
      const newDataArray = action.payload;
      const tempArray = [];

      newDataArray.forEach((newData) => {
        const closedHigh = newData.close > newData.open;

        const index = tempArray.findIndex((item) => item.time === newData.time);

        if (index !== -1) {
          // If the time already exists in arr, remove it
          tempArray.splice(index, 1);
        } else {
          // If the time doesn't exist, add it in ascending order
          tempArray.push({
            time: newData.time,
            position: closedHigh ? 'aboveBar' : 'belowBar',
            color: '#0F6CBD',
            shape: closedHigh ? 'arrowDown' : 'arrowUp',
            text: moment(newData.time).format('DD MMM'),
            greenStick: Number(newData.close) > Number(newData.open),
          });
        }
      });

      // Sort the array after processing all new data
      tempArray.sort((a, b) => new Date(a.time) - new Date(b.time));

      state.turningPoints = tempArray;
    },
    removeTurningPoint: (state, action) => {
      const data = action.payload;
      state.turningPoints = state.turningPoints.filter(
        (vv) => vv.time !== data.time
      );
    },
    resetTurningPoint: (state, action) => {
      state.turningPoints = [];
    },
    setTurningPoints: (state, action) => {
      state.turningPoints = action.payload;
    },

    // ChartData Reducers Below
    setChartData: (state, action) => {
      state.chartData = action.payload;
    },

    // Combinations
    setMoonPhaseCombinations: (state, action) => {
      state.moonPhaseCombinations = action.payload;
    },
    setSelectedMoonPhaseCombinations: (state, action) => {
      const currentState = state.selectedMoonPhaseCombinations;
      const { type, data, dataIndex } = action.payload;

      if (type === 'ADD') {
        // const removedData = state.moonPhaseCombinations.splice(dataIndex, 1);
        // state.selectedMoonPhaseCombinations.splice(
        //   currentState.length,
        //   0,
        //   removedData[0]
        // );ffff
        state.selectedMoonPhaseCombinations.push(data);
      } else if (type === 'REMOVE') {
        try {
          const draftedState = current(state, (draft) => {
            return draft;
          });

          state.selectedMoonPhaseCombinations =
            draftedState.selectedMoonPhaseCombinations.filter(
              (_, ii) => ii !== dataIndex
            );
        } catch (error) {
          console.log('err', error);
        }

        // state.moonPhaseCombinations.unshift(removedData[0]);
      } else if (type === 'RESET') {
        state.selectedMoonPhaseCombinations = [];
      }
    },

    //
    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCollapse,
  setAngleCombination,
  resetAngleCombination,
  addTurningPoint,
  addDefaultTurningPoint,
  removeTurningPoint,
  resetTurningPoint,
  setTurningPoints,
  setSearchParams,
  setUniqueDates,

  // Chart Data Reducers
  setChartData,

  // Combinations
  setMoonPhaseCombinations,
  setSelectedMoonPhaseCombinations,

  //
  setScrollPosition,

  // Loaders
  setCombinationLoader,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
