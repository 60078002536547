import React from 'react';

import Verified from './../../Assets/verified.png';
import { Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

const VerificationComplete = () => {
  const navigate = useNavigate();
  return (
    <div className='verified-ctr'>
      <img src={Verified} className='verified-img' />
      <Typography.Text className='verified-text'>
        Your verification was successful. You are now officially confirmed and
        can enjoy full access to your account. Thank you for choosing us!{' '}
        <span onClick={() => navigate('/auth/login', { replace: true })}>
          Login Now
        </span>
      </Typography.Text>
    </div>
  );
};

export default VerificationComplete;
