import React from 'react';
import { tradingViewLink, tradingViewLogo } from '../../Utils/settings';

export default function TradingView() {
  return (
    <div className='tradingview_ctr'>
      <img className='img' src={tradingViewLogo} />
      Charts powered by{' '}
      <a href={tradingViewLink} target='_blank' className='link'>
        Tradingview
      </a>
    </div>
  );
}
