import { API_URL } from '../../../Utils/settings';

export const loginUrl = API_URL + '/auth/login';

export const getMeUrl = API_URL + '/users/me';

export const addUserUrl = API_URL + '/users/add';

export const requestPasswordResetUrl = API_URL + '/auth/reset-password-request';

export const setNewPasswordUrl = API_URL + '/auth/set-new-password';