import React, { useEffect, useState } from 'react';

import './subscription.css';
import {
  billingCycleEnums,
  currencyEnums,
  getKeyByValue,
  plansBenefits,
} from '../../Utils/settings';
import { Spin, Tabs, Typography, notification } from 'antd';
import {
  cancelSubscriptionAction,
  createSubscriptionAction,
  getAllMySubscriptionsAction,
  getAllPlansAction,
  pauseSubscriptionAction,
  resumeSubscriptionAction,
} from './Action';
import PlanCard from './Components/PlanCard';
import { CheckCircleFilled } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

const AllPlans = () => {
  const cacheAllPlans = localStorage.getItem('allPlans');
  const [allPlans, setAllPlans] = useState(
    cacheAllPlans ? JSON.parse(cacheAllPlans) : []
  );

  const cacheActiveSub = localStorage.getItem('activeSubscription');

  const [activeSubscription, setActiveSubscription] = useState(
    cacheActiveSub ? JSON.parse(cacheActiveSub) : []
  );

  const [cancelLoading, setCancelLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const getMySubscription = async () => {
    await getAllMySubscriptionsAction()
      .then(({ message, error, data }) => {
        if (!error) {
          const activeSub = data.filter((vv) => vv.is_subscription_active);

          if (activeSub.length === 0) {
            localStorage.removeItem('activeSubscription');
          } else {
            setActiveSubscription(activeSub);
            localStorage.setItem(
              'activeSubscription',
              JSON.stringify(activeSub)
            );
          }
        } else {
          notification.error({ message });
        }
      })
      .catch((error) => {
        // logoutUser({ navigate: navigate, error: error });
      });
  };

  useEffect(() => {
    getMySubscription();
  }, []);

  const cancelSubscription = async () => {
    setCancelLoading(true);

    await cancelSubscriptionAction({
      sub_id: activeSubscription[0].stripe_subscription_id,
    })
      .then(({ error, message }) => {
        if (!error) {
          notification.success({ message });
          getMySubscription();
          setActiveSubscription([]);
          localStorage.removeItem('activeSubscription');
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));

    setCancelLoading(false);
  };

  const getAllPlans = () => {
    getAllPlansAction()
      .then(({ data, error, message }) => {
        if (!error) {
          setAllPlans(data);
          localStorage.setItem('allPlans', JSON.stringify(data));
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log(err));
  };

  console.log('allPlans', allPlans);

  useEffect(() => {
    getMySubscription();
    getAllPlans();
  }, []);

  const onSubscribe = async (id) => {
    await createSubscriptionAction({
      payload: {
        subscription_product_id: id,
      },
    })
      .then(({ error, message, data }) => {
        if (!error) {
          window.location.href = data.url;
        } else {
          notification.error({ message });
        }
      })
      .catch((err) => console.log('err', err));
  };

  const onhandleSubscribeButton = (data) => {
    onSubscribe(data.subscription_product_id);
  };

  console.log('activeSubscription', activeSubscription);

  return (
    <div className='plan-main-ctr'>
      <h2 className='ctr-header all-pricing-header'>
        We’ve got a pricing plan that’s perfect for you
      </h2>
      <div className='card_holder pricing-holder'>
        {[
          {
            subscription_product_id: null,
            stripe_product_id: null,
            billing_cycle: 'FREE',
            stripe_price_id: null,
            price: null,
            currency: 2,
          },
          ...allPlans,
        ].map((plan) => {
          const freePlan = plan.billing_cycle === 'FREE';
          const activePlan =
            activeSubscription.length > 0 &&
            activeSubscription[0].billing_cycle === plan.billing_cycle &&
            activeSubscription[0].is_subscription_active;

          return (
            <div className={`card-ctr`}>
              <p className='plan-type'>
                {getKeyByValue(billingCycleEnums, plan.billing_cycle)}
              </p>
              <p className='plan-price'>
                {freePlan
                  ? 'Free For All Users'
                  : `${
                      plan.currency === currencyEnums['INR'] ? '₹' : '₹'
                    } ${''} ${
                      freePlan
                        ? ''
                        : plan.billing_cycle === billingCycleEnums['MONTHLY']
                        ? '800 / m'
                        : '8000 / yr'
                    }`}
              </p>

              <div className='plan_benefits'>
                {plansBenefits[plan.billing_cycle].map((planBenefit) => (
                  <div className='benefit'>
                    <CheckCircleFilled className='icon' />
                    <p>{planBenefit}</p>
                  </div>
                ))}
              </div>
              <a
                className='plan_btn'
                onClick={() => {
                  if (activePlan || freePlan) return;
                  onSubscribe(plan.subscription_product_id);
                }}
              >
                {/* <Spin className='spin-loading' /> */}
                {activePlan
                  ? 'Active Plan'
                  : freePlan
                  ? 'Free Plan'
                  : 'Go Premium'}
              </a>
              {activePlan && (
                <p className='go-premium'>
                  Change Of Mind?{' '}
                  <span onClick={cancelSubscription}>Cancel Now</span>{' '}
                </p>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );

  return (
    <div className='container'>
      <div className={'plans-ctr'}>
        <div className='sub-container'>
          <Typography.Title className='title'>Pricing Plans</Typography.Title>
          <Typography.Text className='subtitle' type='secondary'>
            Each Plan includes 1 License key per purchase, valid for 1 Machine.
            All Plans come with Lifetime free updates. Supported for Windows
            Desktop/Laptop Only.
          </Typography.Text>

          <div className='plan_card_parent'>
            {allPlans.map((vv, index) => (
              <PlanCard
                data={vv}
                index={index}
                onClick={(vv) => onhandleSubscribeButton(vv)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllPlans;
